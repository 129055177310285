import { Hint } from '@ui-kit'
import React from 'react'

interface SecondColumnContentProps {}

const hints = [
  [
    'Naming your website enhances your ability to manage it 1',
    'Naming your website enhances your ability to manage it 2',
    'Naming your website enhances your ability to manage it 3'
  ]
]

const SecondColumnContent: React.FC<SecondColumnContentProps> = () => {
  const hintText = hints[0][Math.floor(Math.random() * hints[0].length)]

  return <Hint>{hintText}</Hint>
}

export default SecondColumnContent
